// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
const {navigator} = window;
const ua = navigator.userAgent.toLowerCase();

export const IS_IOS = /iphone|ipod|ipad/i.test(ua);
export const IS_ANDROID = ua.indexOf('android') > -1;

let IOS_WEBVIEW = false;
if (IS_IOS && !navigator.standalone && !/safari/i.test(ua))
    IOS_WEBVIEW = true;

export {IOS_WEBVIEW};
