// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['/www/earnapp_cp/pub/utils/path.js'], ({parse_qs})=>{
const E = {};

E.stringify_state = (extra={})=>{
    const params = {...parse_qs(), ...extra};
    if (!Object.keys(params).length)
        return null;
    return `state=${encodeURIComponent(JSON.stringify(params))}`;
};

E.parse_state = ()=>{
    const {state} = parse_qs();
    if (!state)
        return {};
    try {
        const parsed = JSON.parse(state);
        return parsed || {};
    } catch(e){
        return {};
    }
};

return E;
});
