// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', '/www/earnapp_cp/pub/utils/constants.js',
    // Import only
    'css!/www/earnapp_cp/pub/css/loading'],
    (React, {APP_TITLE})=>
{
const E = {};

function Loading(){
    return <div className="ea_loading">
      <div className="ea_logo eal_logo" aria-label={`${APP_TITLE} Logo`}/>
    </div>;
}

E.Loading = Loading;
return E;
});
