// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import url from '/util/url.js';
import {url2md5} from '../config.js';
import {PREFIX, PLATFORMS, ROUTES,
    FAQ_LINK_INSTALL} from './constants.js';
import {supports, F} from './features.js';

export const img_url = img=>url2md5(`${PREFIX}/www/earnapp_cp/pub/img/${img}`);

export const get_path = path=>path+window.location.search;

export const g_path = (path='')=>`${PREFIX}${path}`;

export const parse_qs = ()=>url.qs_parse(document.location.search.slice(1));

export const stringify_qs = qs=>url.qs_str(qs);

export const get_referral_link = (base, code='')=>base+code;

export const get_location = ()=>
    encodeURIComponent(get_path(window.location.pathname));

export const INITIAL_QP = parse_qs();

export const LINKS = [{
    id: PLATFORMS.WINDOWS,
    title: 'Windows',
    direct: true,
    ver_required: true,
    icon: 'microsoft_logo',
    ref: (ver, mask=false)=>{
        if (mask)
            return `https://cdn.earnapp.com/static/earnapp-setup-${ver}.exe`;
        return `https://cdn.earnapp.com/static/earnapp-plus-setup-${ver}.exe`;
    }
}, {
    id: PLATFORMS.MACOS,
    title: 'MacOS (Beta)',
    direct: true,
    ver_required: true,
    icon: 'apple_logo',
    ref: ver=>`https://cdn.earnapp.com/static/earnapp-macos-${ver}.pkg`,
}, {
    id: PLATFORMS.IOS,
    title: 'iOS',
    direct: true,
    icon: 'ios_logo',
    ref: ver=>
        'https://apps.apple.com/us/app/bright-rewards-earn-cash/id1645893250',
}, {
    id: PLATFORMS.LINUX,
    title: 'Linux',
    icon: 'linux_logo',
    ref: ()=>FAQ_LINK_INSTALL,
}, {
    id: PLATFORMS.PI,
    title: 'Raspberry Pi',
    icon: 'raspberry_pi_logo',
    ref: ()=>FAQ_LINK_INSTALL,
}, {
    id: PLATFORMS.ANDROID,
    title: 'Android',
    icon: 'android_logo',
    direct: true,
    ref: ver=>`https://cdn.earnapp.com/static/earnapp-${ver}.apk`,
}];

const paths = [{
    id: ROUTES.DASHBOARD,
    label: 'Dashboard',
    to: g_path(''),
    ico: 'dashboard',
    feature: F.DASHBOARD,
}, {
    id: ROUTES.TRANSACTIONS,
    label: 'Transactions',
    to: g_path('/transactions'),
    ico: 'transactions',
    feature: F.TRANSACTIONS,
}, {
    id: ROUTES.REFERRALS,
    label: 'Referrals',
    to: g_path('/referral'),
    ico: 'referral',
    feature: F.REFERRAL,
}, {
    id: ROUTES.REFERRALS_BVPN,
    label: 'Bright VPN Referrals',
    to: g_path('/referral_bvpn'),
    ico: 'referral',
    feature: F.REFERRAL_BVPN,
    bvpn_ref_enabled: true,
}, {
    id: ROUTES.PROMOTIONS,
    label: 'Promotions',
    to: g_path('/promotions'),
    ico: 'podium',
    feature: F.PROMO,
}, {
    id: ROUTES.ME,
    label: 'Profile',
    to: g_path('/me'),
    ico: 'podium',
    exact: false,
    no_sidebar: true,
    feature: F.PROFILE,
}];

export const get_app_paths = bvpn_ref_enabled=>{
    let res = paths.filter(r=>supports(r.feature));
    if (!bvpn_ref_enabled)
        res = res.filter(r=>!r.bvpn_ref_enabled);
    return res;
};
