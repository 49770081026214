// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {Tooltip} from './tooltip.js';
import '../css/text.less';

const headings = {h: 1, H: 1};
const tags_low = `h1 h2 h3 h4 h5 h6 p span strong`;
const tags_up = tags_low.toUpperCase();
const tags = `${tags_low} ${tags_up}`.split(' ');
const sizes = `xxxs xxs xs s m l xl xxl xxxl`.split(' ');

const find_tag = props=>{
    for (let tag of tags)
    {
        if (props[tag])
            return tag;
    }
    return 'p';
};

function Text_comp({
    size,
    children,
    light,
    ghost,
    className,
    mono,
    bold,
    title,
    disabled,
    secondary,
    tertiary,
    accent,
    primary,
    success,
    error,
    warn,
    white,
    tip,
    tip_placement,
    no_wrap,
    ...props
})
{
    const Tag = find_tag(props);
    const classes = cn('ea_text', `ea_text_${size}`, className);
    let is_bold = bold;
    if (headings[Tag[0]])
        is_bold = true;
    const classname = cn(classes, {
        ea_text_mono: mono,
        ea_text_bold: is_bold,
        ea_text_light: light,
        ea_text_ghost: ghost,
        ea_text_disabled: disabled,
        ea_text_secondary: secondary,
        ea_text_tertiary: tertiary,
        ea_text_primary: primary,
        ea_text_success: success,
        ea_text_error: error,
        ea_text_warn: warn,
        ea_text_white: white,
        ea_text_accent: accent,
        ea_text_no_wrap: no_wrap,
    });
    return <Tooltip tip={tip} tip_placement={tip_placement}>
      <Tag className={classname} title={title}>
        {children}
      </Tag>
    </Tooltip>;
}

const gen_text = size=>function Text_proxy({...props}){
    return <Text_comp size={size} {...props}/>;
};

export const Text = sizes.reduce((acc, size)=>{
    acc[size] = gen_text(size);
    return acc;
}, {});
