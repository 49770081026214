// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {OFW_SIGN_TYPE} from '../../utils/constants.js';
import store from '../../store.js';
import {Button} from '../../comp/button';
import {Balance} from '../../comp/balance';
import {Removed_badge} from '../../comp/removed_badge';
import {Profile_popup} from '../profile_popup';
import {Text} from '../../comp/text.js';
import logo_src from '../../img/earnapp_logo.svg';
import * as cns from './header.module.less';

export function Header({className, set_sign}){
    const [user] = store.use_user();
    const is_login = !!user;
    return <div className={cn(cns.root, className)}>
      <div className={cns.container}>
        <a className={cns.logo_wrap} href="/">
          <img className={cns.logo} src={logo_src}/>
          {user?.is_root&&
            <Text.xs className={cns.roots} bold>for roots</Text.xs>
          }
        </a>
        <div className={cns.right}>
          {!!user.delete_ts&&<Removed_badge user={user}/>}
          {is_login
            ? <>
              <Balance compact/>
              <Profile_popup user={user}/>
            </>
            : <>
              <Button color_scheme="secondary" filled size="s"
                on_click={()=>set_sign(OFW_SIGN_TYPE.SIGN_IN)}>
                Sign in
              </Button>
              <Button color_scheme="secondary" filled size="s"
                on_click={()=>set_sign(OFW_SIGN_TYPE.SIGN_UP)}>
                Sign up
              </Button>
            </>
          }
        </div>
      </div>
    </div>;
}
