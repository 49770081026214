// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', 'cookie', '/util/conv.js', '/util/date.js',
    '/util/etask.js', '/www/earnapp_cp/pub/utils/constants.js',
    '/www/earnapp_cp/pub/api.js'
], (React, cookie, conv, date, etask, constants, api)=>{
const E = {};
const {useEffect, useState, useCallback} = React;
const {ms} = date;
const {XSRF_COOKIE, OFFER_STATUS} = constants;

E.fmt_money = (v, dec=2)=>{
    const ret = '$'+(+v||0).toFixed(dec);
    return ret.endsWith('.'+'0'.repeat(dec)) ? ret.slice(0, -dec-1) : ret;
};

E.bw_to_readable = bw=>(conv.scaled_number(bw, {base: 1024})||0)+'B';

const update_xsrf = (update, callback)=>etask(function*(){
    const current = cookie.get(XSRF_COOKIE);
    if (!current)
        yield update();
    callback();
});

E.use_xsrf_rotation = update=>{
    const [ready, set_ready] = useState(false);
    useEffect(()=>{
        const updater = update_xsrf.bind(1, update, ()=>set_ready(true));
        const interval = setInterval(updater, 10*ms.MIN);
        updater();
        return ()=>clearInterval(interval);
    }, []);
    return ready;
};

const fetch_statuses = (devices, callback)=>etask(function*(){
    const res = yield api.get_device_statuses(devices);
    if (!res.error)
        callback(res);
});

E.use_list_pagination = (list, opt={})=>{
    const [page, set_page] = useState(1);
    const [page_size, set_page_size] = useState(opt.page_size || 30);
    const [total_pages, set_total_pages] = useState(0);
    const [shown_count, set_shown_count] = useState(0);
    const [data, set_data] = useState([]);
    const [prev_available, set_prev_available] = useState(false);
    const [next_available, set_next_available] = useState(false);

    // Calculate total pages
    useEffect(()=>{
        const new_total_pages = Math.ceil((list||[]).length / page_size);
        set_total_pages(new_total_pages);
        set_page(1);
    }, [list, page_size]);

    // Update visible data based on the current page
    useEffect(()=>{
        const start = (page - 1) * page_size;
        const end = start + page_size;
        const new_data = (list||[]).slice(start, end);
        set_data(new_data);
        set_shown_count(new_data.length);
        set_prev_available(page > 1);
        set_next_available(page < total_pages);
    }, [list, page, page_size, total_pages]);

    // Function to set page directly
    const set_page_directly = useCallback(page_number=>{
        const new_page = Math.max(1, Math.min(page_number, total_pages));
        set_page(new_page);
    }, [total_pages]);

    // Functions to navigate to the next and previous pages
    const next = useCallback(()=>{
        set_page(current_page=>current_page < total_pages
            ? current_page + 1
            : current_page);
    }, [total_pages]);

    const prev = useCallback(()=>{
        set_page(current_page=>current_page > 1
            ? current_page - 1
            : current_page);
    }, []);

    return {page, page_size, total_pages, data, prev_available, next_available,
        next, prev, set_page_size, shown_count, set_page: set_page_directly};
};

E.use_device_statuses = devices=>{
    const [statuses, set_statuses] = useState(null);
    useEffect(()=>{
        if (!devices?.length)
            return;
        const updater = fetch_statuses.bind(null, devices, set_statuses);
        const interval = setInterval(updater, 3*ms.MIN);
        updater();
        return ()=>clearInterval(interval);
    }, [devices]);
    return statuses;
};

E.is_offer_started = offer=>
    offer.status==OFFER_STATUS.STARTED
    || offer.status==OFFER_STATUS.IN_PROGRESS;

E.is_offer_done = offer=>offer.status==OFFER_STATUS.COMPLETED;

E.limit_txt = (txt, limit=10)=>{
    if (txt.length-limit>3)
        return `${txt.slice(0, limit)}...`;
    return txt;
};

return E;
});
