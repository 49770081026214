// extracted by mini-css-extract-plugin
var _1 = "Nz5FJb_cF1gI_J8LRC_O";
var _2 = "J6oQe5ue_1ZrEBEL4vTC";
var _3 = "oSBOnZIY2TaR6cXT1_bC";
var _4 = "oQKtlPRTihVXpEUBVkom";
var _5 = "RRCEta67iEXUJ2OCl0WQ";
var _6 = "yBf2S6GJoimYc4Myul7o";
var _7 = "srgkUY2_EUdS8eL5ED6p";
var _8 = "kZa4CSb7eUGq9V01s0kU";
export { _1 as "avatar", _2 as "name", _3 as "name_wrap", _4 as "root", _5 as "root__big", _6 as "root__clickable", _7 as "root__narrow_hide_name", _8 as "root__no_bg" }
