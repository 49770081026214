// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useCallback, useState} from 'react';
import date from '/util/date.js';
import {User} from '../../comp/user';
import {Text} from '../../comp/text.js';
import {Popup} from '../../comp/popup.js';
import {DATE_FMT_LONG} from '../../utils/constants.js';
import {Logout_button} from '../../comp/logout_button.js';
import {Delete_account} from '../../comp/delete_account.js';
import * as cns from './profile_popup.module.less';

const fmt = d=>date.strftime(DATE_FMT_LONG, d);
const EMPTY_OBJ = {};

function Content({user, on_close}){
    return <div className={cns.profile}>
      <User user={user} big no_bg adaptive_opts={EMPTY_OBJ}/>
      <div className={cns.info}>
        <Text.xl>Information</Text.xl>
        <ul className={cns.info_list}>
          <li className={cns.info_item}>
            <Text.l tertiary>Email</Text.l>
            <Text.l className={cns.value}>{user.email}</Text.l>
          </li>
          <li className={cns.info_item}>
            <Text.l tertiary>Date joined</Text.l>
            <Text.l className={cns.value}>{fmt(user.joined_ts)}</Text.l>
          </li>
        </ul>
      </div>
      <div className={cns.foot}>
        <Logout_button />
        <Delete_account user={user} on_close={on_close}/>
      </div>
    </div>;
}

export function Profile_popup({user, className}){
    const [show_popup, set_show_popup] = useState(false);
    const toggle_popup = useCallback(()=>{
        set_show_popup(s=>!s);
    }, []);
    return <React.Fragment>
      <User className={className} user={user} on_click={toggle_popup}/>
      <Popup title="Profile" show={show_popup} on_close={toggle_popup}
        hide_actions>
        <Content user={user} on_close={toggle_popup}/>
      </Popup>
    </React.Fragment>;
}
