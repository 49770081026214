// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import {Link} from 'react-router-dom';
import {IOS_WEBVIEW} from '../utils/constants.js';
import google_auth from '../utils/google_oauth.js';
import {g_path, get_path, parse_qs} from '../utils/path.js';
import {Panel} from '../comp/panel.js';
import {Page} from '../comp/page';
import {Text} from '../comp/text.js';
import apple_login from '../comp/apple_login.js';
import {Oauth_btn} from '../comp/oauth_btn.js';
import '../css/signin.less';

export class Signin extends React.Component {
    constructor(props){
        super(props);
        apple_login.init.call(this, {force_beta: IOS_WEBVIEW});
    }
    componentDidMount(){
        apple_login.prepare.call(this);
        const qs = parse_qs();
        this.setState({err_type: qs?.type});
    }
    render(props){
        let err_msg = '';
        if (this.state.err_type == 'suspended')
            err_msg = 'Account Suspended';
        const apple_login_btn = apple_login.render.call(this,
            ['Log in with Apple']);
        return <Page className="ea_signin" sidebarless headerless has_logo>
          <Panel className="eas_block" no_background no_border>
            {err_msg &&
              <Text.m className="eas_title" h2 bold>Account Suspended</Text.m>}
            <Text.m className="eas_title" h2 bold>Sign In</Text.m>
            <div className="eas_separator"/>
            <ul className="eas_actions">
              {!IOS_WEBVIEW && <li className="eas_action">
                <Oauth_btn url={google_auth.get_url(true)}
                  logo="google_logo">Log in with Google</Oauth_btn>
              </li>}
              {apple_login_btn && <li className="eas_action">{apple_login_btn}
              </li>}
            </ul>
            <div className="eas_switch">
              <Text.s className="eas_qestion">Don't have an account?</Text.s>
              <Link className="eas_link" to={get_path(g_path('/signup'))}>
                Sign up</Link>
            </div>
          </Panel>
        </Page>;
    }
}
