// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react',
    '/www/earnapp_cp/pub/api.js',
    '/www/earnapp_cp/pub/store.js',
    '/www/earnapp_cp/pub/comp/alert.js',
    '/www/earnapp_cp/pub/utils/constants.js',
    '/www/earnapp_cp/pub/utils/path.js',
    '/util/url.js',
    '/util/etask.js',
],
    (React, api, store, {alert}, {LINK_Q_PARAM, PLATFORM_Q_PARAM},
        {g_path, parse_qs}, url, etask)=>
{
const {useEffect} = React;
const E = {};

const register_device = (code, platform)=>etask(function*(){
    window.history?.replaceState(null, document.title, g_path(''));
    let res;
    try {
        res = yield api.link_device({code, platform});
    } catch(e){
        res = {error: e.message};
    }
    if (res.redirect_url)
        window.location.assign(res.redirect_url);
    if (res.error)
    {
        alert({
            title: 'Error while linking device',
            text: res.error,
            negative: true,
        });
        return false;
    }
    store.use_devices.invalidate();
    store.use_money.invalidate();
    store.use_speedtest.invalidate();
    alert({text: 'Device successfully linked',
        positive: true});
    return true;
});

function _link_observer(u){
    let params = u ? url.qs_parse(u.search?.slice(1)||'') : parse_qs();
    if (params.state)
    {
        params = JSON.parse(params.state);
        if (params.redirect)
            return _link_observer(url.parse(params.redirect));
    }
    if (params[LINK_Q_PARAM])
        return register_device(params[LINK_Q_PARAM], params[PLATFORM_Q_PARAM]);
}
function Link_observer(){
    useEffect(()=>{
        let fn;
        if (fn = _link_observer())
            requestAnimationFrame(()=>fn);
    }, []);
    return null;
}

E._link_observer = _link_observer;
E.Link_observer = Link_observer;
return E;
});
