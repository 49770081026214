// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react',
    '/www/earnapp_cp/pub/utils/path.js',
    '/www/earnapp_cp/pub/api.js',
    '/www/earnapp_cp/pub/comp/oauth_btn.js'],
    (React, {parse_qs}, api, {Oauth_btn})=>
{
// component behavior, called with context
const E = {
    init({force_beta}){
        this.state = {...this.state,
            apple_login_url: undefined,
            apple_login_force_beta: force_beta
        };
    },
    prepare(){
        const beta = this.state.apple_login_force_beta||parse_qs().beta;
        // fetch actual URL in runtime to follow config changes
        api.get_login_url('apple', beta)
        .then(({url})=>this.setState({apple_login_url: url}));
    },
    render(title){
        const {apple_login_url} = this.state;
        if (!apple_login_url)
            return null;
        return <Oauth_btn className="apple-login" url={apple_login_url}
          logo="apple_logo2">{title}</Oauth_btn>;
    },
};
return E;
});
