// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {APP_TITLE} from '../../utils/constants.js';
import {Header} from '../../features/header';
import {Footer} from '../../features/footer';
import logo_url from '../../img/earnapp_logo.svg';
import {Sidebar} from '../sidebar.js';
import * as cns from './page.module.less';

const logo_style = {backgroundImage: `url(${logo_url})`};

export function Page({
    children,
    className,
    sidebarless,
    headerless,
    footerless,
    has_logo=false,
})
{
    const Wrapper = sidebarless ? React.Fragment : 'div';
    const wrapper_props = {};
    if (!sidebarless)
        wrapper_props.className = cns.right;
    const classes = cn(cns.root, {
        root__sideless: sidebarless,
        root__headerless: headerless,
    });
    return <div className={classes}>
      {!headerless&&<Header/>}
      <div className={cns.body}>
        {!sidebarless&&<Sidebar className={cns.sidebar}/>}
        <Wrapper {...wrapper_props}>
          <main className={cn(cns.main, className)}>
            {has_logo&&
              <a className={cns.logo} aria-label={`${APP_TITLE} Logo`}
                href="/" rel="noreferrer noopener" style={logo_style}/>
            }
            {children}
          </main>
        </Wrapper>
      </div>
      {!footerless&&<Footer/>}
    </div>;
}
