// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', 'classnames', 'react-router-dom',
    '/www/earnapp_cp/pub/comp/button',
    '/www/earnapp_cp/pub/utils/path.js',
    '/www/earnapp_cp/pub/utils/constants.js',
    '/www/earnapp_cp/pub/store.js',
    '/svc/wbm/pub/earnapp_util.js',
    // Import only
    'css!/www/earnapp_cp/pub/css/sidebar'],
    (React, cn, react_router, {Button},
    {get_path, get_app_paths}, {ROUTES},
    store, statuses)=>
{
const {useMemo} = React;
const {withRouter} = react_router;
const E = {};
const {STATUS_CREATED, STATUS_APPROVED, STATUS_PENDING_PROCEDURE,
    STATUS_PENDING_PAID} = statuses;

const pending_statuses = [
    STATUS_CREATED,
    STATUS_APPROVED,
    STATUS_PENDING_PROCEDURE,
    STATUS_PENDING_PAID,
];

function Sidebar({match, className}){
    const [user] = store.use_user();
    const show_ref = user.bvpn_ref_enabled||user.is_root;
    const items = useMemo(()=>get_app_paths(show_ref), []);
    const [{list: transactions}] = store.use_transactions();
    const count = useMemo(()=>transactions?.reduce((acc, {status})=>
        pending_statuses.includes(status) ? acc+1 : acc, 0), [transactions]);
    return <aside className={cn('ea_sidebar', className)}>
      <ul className="eas_list">
        {items.map(i=>{
            const active = match&&match.path == i.to;
            if (i.no_sidebar)
                return null;
            return <li key={i.id} className={cn('eas_item',
              i.no_mobile&&'eas_desktop_only')}>
              <Button ico={i.ico} href={get_path(i.to)}
                className="eas_button" align="left" size="m"
                dot_count={i.id==ROUTES.TRANSACTIONS ? count : undefined}
                color_scheme={active ? 'primary' : 'tertiary'}
                view={active ? 'button' : 'link'} filled={active}>
                {i.label}
              </Button>
            </li>;
        })}
      </ul>
    </aside>;
}

E.Sidebar = withRouter(Sidebar);
return E;
});
