// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['/www/earnapp_cp/pub/config.js',
    '/www/earnapp_cp/pub/utils/env.js'], ({ver}, env)=>{
const E = {};

E.GOOGLE_CLIENT_ID = window.GOOGLE_CLIENT_ID;
if (!E.GOOGLE_CLIENT_ID)
    throw new Error('GOOGLE_CLIENT_ID not found!');
E.APP_ID = window.APP_ID;
if (!E.APP_ID)
    throw new Error('APP_ID not found!');
E.AVAILABLE_FEATURES = window.AVAILABLE_FEATURES;
if (!E.AVAILABLE_FEATURES)
    throw new Error('AVAILABLE_FEATURES not found!');
E.APP_TITLE = window.APP_TITLE;
if (!E.APP_TITLE)
    throw new Error('APP_TITLE not found!');
E.WISE_ENABLED = window.WISE_ENABLED;
E.SHOW_LEADERBOARD = window.SHOW_LEADERBOARD;
E.CONTEST_TITLE = window.CONTEST_TITLE;
E.DASHBOARD_DISABLED = window.DASHBOARD_DISABLED;
E.CONTEST_DESCRIPTION = window.CONTEST_DESCRIPTION;
E.FEATURES = {
    DASHBOARD: 'dashboard',
    TRANSACTIONS: 'transactions',
    PROMO: 'promotions',
    PROFILE: 'profile',
    REFERRAL: 'referral',
    REFERRAL_BVPN: 'referral_bvpn',
    NOTIFS: 'notifications',
    DOWNLOADS: 'downloads',
    USAGE_CHART: 'usage_chart',
    FAQ: 'faq',
    SOCIAL: 'social',
};
E.DOMAIN = location.hostname;
E.PREFIX = '/dashboard';
E.REFERRAL_BASE = `https://${E.DOMAIN}/i/`;
E.REFERRAL_BASE_BVPN = 'https://brightvpn.com/?r=';
E.FAQ_LINK = 'earnapp'==E.APP_ID
    ? 'https://help.earnapp.com' : `https://${E.DOMAIN}/faq`;
E.FAQ_LINK_REFERRAL = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10232037405073-How-does-the-referral-program-work-';
E.FAQ_LINK_BVPN_REFERRAL = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10247641869969-What-is-EarnApp-s-Referral-Program-';
E.FAQ_LINK_BVPN_REF_RATES = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10247867472401-What-are-the-rates-';
E.FAQ_LINK_VPN = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10201052442897-Why-was-my-IP-banned-';
E.FAQ_LINK_INSTALL = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10261224561553-Installation-instructions';
E.APPESTEEM_LINK = 'https://customer.appesteem.com/certified?vendor=BDATA';
E.VERSION = ver;
E.LS_PREFIX = 'earnapp';
E.MOBILE_WIDTH = 768;
E.REF_Q_PARAM = 'r';
E.LINK_Q_PARAM = 'link_device';
E.PLATFORM_Q_PARAM = 'platform';
E.TIME_FMT = '%H:%M';
E.DATE_TIME_FMT = '%d-%m-%Y %H:%M';
E.DATE_TIME_US_FMT = '%m-%d-%Y %H:%M';
E.DATE_FMT = '%d-%m-%Y';
E.DATE_FMT_SHORT = '%b %d';
E.DATE_FMT_LONG = '%B %-d, %Y';
E.XSRF_COOKIE = 'xsrf-token';
E.XSRF_HEADER = 'xsrf-token';
E.XSRF_STATE = 'xsrf';
E.METHODS = {
    PAYPAL: 'paypal.com',
    AMAZON: 'generic_amazon',
    WISE: 'wise.com'
};
E.P_METHOD_META = {
    [E.METHODS.PAYPAL]: {
        title: 'PayPal',
    },
    [E.METHODS.AMAZON]: {
        title: 'Amazon Gift Card',
    },
    [E.METHODS.WISE]: {
        title: 'Wise',
    },
};
E.METHOD_KINDS = [
    E.METHODS.PAYPAL,
    E.METHODS.AMAZON,
];
if (E.WISE_ENABLED)
    E.METHOD_KINDS.push(E.METHODS.WISE);
E.EXECUTION_DURATIONS = {
    [E.METHODS.PAYPAL]: 7,
    [E.METHODS.AMAZON]: 10,
    [E.METHODS.WISE]: 7,
};
E.PLATFORMS = {
    WINDOWS: 'win',
    LINUX: 'linux',
    MACOS: 'mac',
    IOS: 'ios',
    ANDROID: 'android',
    PI: 'pi',
};
E.ROUTES = {
    DASHBOARD: 'dashboard',
    TRANSACTIONS: 'transactions',
    REFERRALS: 'referrals',
    REFERRALS_BVPN: 'referrals_bvpn',
    PROMOTIONS: 'promotions',
    FAQ: 'faq',
    MAINTENANCE: 'maintenance',
    OFFERS: 'offers',
    ME: 'profile',
};
E.STORAGE_KEYS = {
    USER: 'root.user',
};
E.SUPPORT_EMAIL = 'support@earnapp.com';
E.BILLING = {
    UPTIME: 'uptime',
    BANDWIDTH: 'bandwidth',
    FIXED: 'fixed'
};
E.UPTIME_BILLING = [E.BILLING.UPTIME, E.BILLING.FIXED];
E.DEF_PERIOD_ID = 1;
E.PERIODS = [
    {id: 0, title: 'All time'},
    {id: 1, title: 'Last month'},
];

E.IS_IOS = env.IS_IOS;
E.IS_ANDROID = env.IS_ANDROID;
E.IOS_WEBVIEW = env.IOS_WEBVIEW;

E.OFW_USER_TYPE = {
    AUTH: 'auth',
    NO_AUTH: 'no_auth',
};

E.OFW_SIGN_TYPE = {
    SIGN_IN: 'sign_in',
    SIGN_UP: 'sign_up',
};

E.STORAGE_REDIRECT = 'storage_redirect';

E.OFFER_STATUS = {
    NEW: 'new',
    STARTED: 'started',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
};
E.YEAR = new Date().getFullYear();
E.NEW_TAB_ATTRS = {
    target: '_blank',
    rel: 'noreferrer noopener'
};

return E;
});
