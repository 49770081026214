// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', 'react-router-dom', '/www/earnapp_cp/pub/comp/panel.js',
    '/www/earnapp_cp/pub/comp/text.js',
    '/www/earnapp_cp/pub/utils/google_oauth.js',
    '/www/earnapp_cp/pub/comp/page',
    '/www/earnapp_cp/pub/comp/link_observer.js',
    '/www/earnapp_cp/pub/utils/path.js',
    '/www/earnapp_cp/pub/utils/url_state.js',
    '/util/etask.js',
    '/www/earnapp_cp/pub/utils/local_storage.js',
    '/www/earnapp_cp/pub/utils/constants.js',
    // Import only
    'css!/www/earnapp_cp/pub/css/auth_success'],
    (React, react_router, {Panel}, {Text}, {register_token}, {Page},
    {_link_observer}, {g_path}, {parse_state}, etask, local_storage,
    {STORAGE_REDIRECT})=>
{
const {Redirect} = react_router;
const {useEffect, useState} = React;
const E = {};

const get_redirect_path = ()=>{
    const saved_state = parse_state();
    if (saved_state.redirect)
        return saved_state.redirect;
    const local_redirect = local_storage.read(STORAGE_REDIRECT);
    if (local_redirect)
    {
        local_storage.cleanup(STORAGE_REDIRECT);
        return g_path(local_redirect);
    }
    return g_path('');
};

function Auth_success(){
    const [success, set_success] = useState(false);
    const [fail, set_fail] = useState(false);
    const [fail_type, set_fail_type] = useState('');
    useEffect(()=>{
        etask(function*(){
            try {
                const res = yield register_token();
                if (res?.ok)
                {
                    _link_observer();
                    set_success(true);
                }
                else
                {
                    _link_observer();
                    if (res?.err == 'user suspended')
                        set_fail_type('suspended');
                    else
                        set_fail_type('');
                    set_fail(true);
                }
            } catch(e){
                _link_observer();
                set_fail(true);
            }
        });
    }, [success, fail]);
    if (success)
        return <Redirect to={get_redirect_path()}/>;
    if (fail)
    {
        let url = '/signin';
        if (fail_type)
            url += '?type='+fail_type;
        return <Redirect to={g_path(url)}/>;
    }
    return <Page className="ea_auth_success" sidebarless headerless has_logo>
      <Panel className="eaas_block" no_background no_border>
        <div className="eaas_content">
          <Text.l>Checking login credentials</Text.l>
          <Text.m>Redirecting...</Text.m>
        </div>
      </Panel>
    </Page>;
}

E.Auth_success = Auth_success;
return E;
});
