// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['cookie', '/util/string.js', '/util/etask.js',
    '/www/earnapp_cp/pub/api.js', '/www/earnapp_cp/pub/utils/path.js',
    '/www/earnapp_cp/pub/utils/constants.js',
    '/www/earnapp_cp/pub/utils/url_state.js'],
    (cookie, {qw}, etask, api, {parse_qs}, constants,
    {stringify_state, parse_state})=>
{
const {PREFIX, DOMAIN, GOOGLE_CLIENT_ID, XSRF_COOKIE,
    XSRF_HEADER, XSRF_STATE} = constants;
const E = {};
const SCOPES = qw`https://www.googleapis.com/auth/userinfo.email
    https://www.googleapis.com/auth/userinfo.profile`;
const RESPONSE_TYPE = 'code';
const AUTH_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';
const AUTH_TYPE = 'google';
const CALLBACK_URL = `https://${DOMAIN}${PREFIX}/auth/google/callback`;
const FORCE_REFRESH_TOKEN = `prompt=consent&access_type=offline`;
const XSRF_EROR = 'Missing XSRF';

E.get_url = force_refresh=>{
    const state = stringify_state({
        [XSRF_STATE]: cookie.get(XSRF_COOKIE),
    });
    let params = [
        `response_type=${RESPONSE_TYPE}`,
        `client_id=${GOOGLE_CLIENT_ID}`,
        `scope=${SCOPES.map(encodeURIComponent).join(' ')}`,
        `redirect_uri=${encodeURIComponent(CALLBACK_URL)}`,
        ...state ? [state] : [],
    ].join('&');
    if (force_refresh)
        params += `&${FORCE_REFRESH_TOKEN}`;
    return `${AUTH_ENDPOINT}?${params}`;
};

E.register_token = ()=>etask(function*(){
    const state = parse_state();
    const xsrf_token = state?.[XSRF_STATE];
    if (!xsrf_token)
        throw new Error(XSRF_EROR);
    const qparams = parse_qs();
    return yield api.exchange_token({
        [XSRF_HEADER]: xsrf_token,
        type: AUTH_TYPE,
        code: qparams.code,
        redirect_uri: CALLBACK_URL
    });
});

return E;
});
