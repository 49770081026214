// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define([], ()=>{
    const {ea_conf={}} = window;
    const E = {
        ver: ea_conf.ver,
        config: ea_conf,
    };
    const prefix = ea_conf.url_prefix;
    E.url2md5 = url=>`${prefix}${url}?v=${E.ver}`;
    console.log('earnapp_cp', E.ver);
    return E;
});
