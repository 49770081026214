// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import debounce from 'lodash4/debounce.js';
import '../css/alert.less';

const CLASSES = {
    CONTAINER: 'ea_alert',
    UNIT: 'eaa_unit',
    NEGATIVE: 'eaa_unit_negative',
    POSTIVE: 'eaa_unit_positive',
    TITLE: 'eaa_title',
    TEXT: 'eaa_text',
    LIST: 'eaa_list',
};
const MOBILE_BREAKPOINT = 600;
const UPDATE_SHIFT_FREQUENCY = 500;
const DEFAULT_SHIFT = '320px';
const MOBILE_SHIFT = '100vw';
const DIMENSIONS = {shift: DEFAULT_SHIFT};
const GAP = 4;
const CHECK_INTERVAL = 500;
const DEFAULT_LIFETIME = 5000;

const recognize_width = debounce(()=>{
    const width = document.documentElement.clientWidth;
    DIMENSIONS.shift = width>MOBILE_BREAKPOINT ? DEFAULT_SHIFT : MOBILE_SHIFT;
}, UPDATE_SHIFT_FREQUENCY);

// interface Notification {
//   title: string;
//   text: string;
//
//   negative?: boolean;
//   positive?: boolean;
//   lifetime?: number;
//   className?: string;
// }

class Alerts {
    constructor(){
        this.click_handler = this.click_handler.bind(this);
        this.notifications = [];
        this.container_node = null;
        this.check_interval = null;
        window.addEventListener('resize', ()=>recognize_width());
        recognize_width();
    }
    push(notif){
        const toPush = Array.isArray(notif) ? notif : [notif];
        const container = this.get_container()
            .querySelector(`.${CLASSES.LIST}`);
        const fragment = document.createDocumentFragment();
        for (let i = 0; i<toPush.length; i++)
            fragment.appendChild(this.cook_notification(toPush[i], i));
        container.appendChild(fragment);
        if (!this.check_interval)
        {
            this.check_interval = window.setInterval(
                this.clear_tick.bind(this), CHECK_INTERVAL);
        }
    }
    cook_notification(notif, index){
        const node = this.create_notification(notif);
        const timestamp = Date.now();
        const cloned = {
            ...notif,
            id: `${timestamp}_${index}`,
            node,
            timestamp,
            shift: 0,
        };
        node.dataset.id = cloned.id;
        if (this.notifications.length)
        {
            const last = this.notifications[this.notifications.length-1];
            cloned.shift = last.shift+last.node.offsetHeight+GAP;
        }
        cloned.node.style.transform += ` translateY(${cloned.shift}px)`;
        this.notifications.push(cloned);
        return node;
    }
    clear_tick(){
        const currentTime = Date.now();
        const toRemove = {};
        let shift = 0;
        for (let i = 0; i<this.notifications.length; i++)
        {
            const timeDelta = this.notifications[i].lifetime||DEFAULT_LIFETIME;
            if (currentTime - this.notifications[i].timestamp>timeDelta)
            {
                this.remove_notification(this.notifications[i].node);
                toRemove[i] = true;
            }
            else
            {
                this.notifications[i].shift = shift;
                this.notifications[i].node.style.transform =
                    `translateY(${shift}px)`;
                shift += this.notifications[i].node.offsetHeight+GAP;
            }
        }
        this.notifications = this.notifications
            .filter((_, index)=>!toRemove[index]);
        if (!this.notifications.length)
        {
            clearInterval(this.check_interval);
            this.check_interval = null;
        }
    }
    get_container(){
        let node = this.container_node;
        if (!node)
        {
            node = this.create_container();
            this.container_node = node;
        }
        return node;
    }
    remove_notification(elem){
        elem.style.transform += ` translateX(${DIMENSIONS.shift})`;
        setTimeout(elem.remove.bind(elem), 1000);
    }
    create_container(){
        const node = document.createElement('aside');
        const ul = document.createElement('ul');
        node.classList.add(CLASSES.CONTAINER);
        ul.classList.add(CLASSES.LIST);
        node.appendChild(ul);
        node.addEventListener('click', this.click_handler);
        document.body.appendChild(node);
        return node;
    }
    create_notification(notif){
        const node = document.createElement('li');
        node.classList.add(CLASSES.UNIT);
        if (notif.className)
            node.classList.add(notif.className);
        if (notif.positive)
            node.classList.add(CLASSES.POSTIVE);
        else if (notif.negative)
            node.classList.add(CLASSES.NEGATIVE);
        const titleNode = document.createElement('h2');
        const textNode = document.createElement('p');
        titleNode.classList.add(CLASSES.TITLE);
        textNode.classList.add(CLASSES.TEXT);
        titleNode.textContent = notif.title;
        textNode.textContent = notif.text;
        node.style.transform = `translateX(${DIMENSIONS.shift})`;
        node.appendChild(titleNode);
        node.appendChild(textNode);
        return node;
    }
    click_handler(event){
        if (event.target instanceof Element)
        {
            const elem = event.target.closest(`.${CLASSES.UNIT}`);
            if (elem instanceof HTMLElement)
                this.hide_notif(elem.dataset.id);
        }
    }
    hide_notif(id){
        for (const notif of this.notifications)
        {
            if (notif.id==id)
            {
                notif.lifetime = 1;
                break;
            }
        }
    }
}

let instance = null;

export function alert(data){
    if (!instance)
        instance = new Alerts();
    instance.push(data);
}
