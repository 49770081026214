// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useMemo} from 'react';
import cn from 'classnames';
import {Tooltip} from '../tooltip.js';
import * as list from './list.js';
import './icon.less';

const img_style = {display: 'inline-flex', width: '100%', height: '100%'};

function Img_inner({
    className,
    src,
    size,
    tip,
    tip_placement,
})
{
    const style = useMemo(()=>{
        const res = {...img_style};
        res.height = size+'px';
        res.width = size+'px';
        return res;
    }, [size, img_style]);
    return <Tooltip tip={tip} placement={tip_placement}>
      <img className={className} style={style} src={src}/>
    </Tooltip>;
}

function Span_inner({className, src, tip, tip_placement}){
    const style = useMemo(()=>({
        'WebkitMaskImage': `url("${src}")`,
    }), [src]);
    return <Tooltip tip={tip} placement={tip_placement}>
      <span className={cn(className)} style={style}/>
    </Tooltip>;
}

export function Icon({
    className,
    ico='default',
    size='m',
    color='light',
    as='span',
    src: in_src,
    tip,
    tip_placement,
    title,
})
{
    const ico_class = `eai_${ico}`;
    const size_class = `eai_size_${size}`;
    let src = in_src || list[ico];
    if (!src)
    {
        console.error('Svg: unknown svg ico '+ico);
        src = '';
    }
    const is_img = as=='img';
    const color_class = is_img ? '' : `eai_color_${color}`;
    const Comp = is_img ? Img_inner : Span_inner;
    return <Comp className={cn('ea_icon', color_class, ico_class,
      size_class, is_img&&'ea_icon__img', className)} src={src}
      tip={tip||title} tip_placement={tip_placement}/>;
}
