// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', 'classnames', '/www/earnapp_cp/pub/comp/button',
    '/www/earnapp_cp/pub/api.js', '/util/etask.js'],
    (React, cn, {Button}, api, etask)=>
{
const {useCallback} = React;
const E = {};

function Logout_button({className}){
    const handle_logout = useCallback(()=>etask(function*(){
        yield api.logout();
        document.location = '/';
    }), []);
    return <Button className={cn('ea_logout_button', className)}
      on_click={handle_logout} view="link" ico="logout" left_shift>
      Log out
    </Button>;
}

E.Logout_button = Logout_button;
return E;
});
