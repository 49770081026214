// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', 'classnames',
    '/www/earnapp_cp/pub/comp/button',
    // Import only
    'css!/www/earnapp_cp/pub/css/social'],
    (React, cn, {Button})=>
{
const E = {};

function Social({className, items}){
    return <div className={cn('ea_social', className)}>
      {items.map(({url, name, ico})=><Button href={url} key={url}
        className="eass_link" title={name} ico={ico} hide_new_tab_ico
        color_scheme="tertiary" filled>
      </Button>)}
    </div>;
}

E.Social = Social;
return E;
});
