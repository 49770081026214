// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', 'react-dom', 'classnames',
    '/www/earnapp_cp/pub/comp/text.js',
    '/www/earnapp_cp/pub/comp/panel.js',
    '/www/earnapp_cp/pub/comp/button',
    '/www/earnapp_cp/pub/comp/icon',
    // Import only
    'css!/www/earnapp_cp/pub/css/popup'],
    (React, react_dom, cn, {Text}, {Panel}, {Button}, {Icon})=>
{
const {useState, useEffect, useCallback, useRef} = React;
const {createPortal} = react_dom;
const CLOSE_ANIMATION_DURATION = 150;
const container = document.createElement('div');
const CONTAINER_CLASS = 'ea_popups';
container.classList.add(CONTAINER_CLASS);
document.body.appendChild(container);
let backdrop;
let serial = 0;
const popups = [];

const E = {};

const activate_popup = (opt={})=>{
    const pop = document.createElement('div');
    pop.classList.add('eap_popup');
    const has_backdrop = !!container.querySelectorAll('.eap_backdrop').length;
    if (!has_backdrop && !opt.no_backdrop)
    {
        backdrop = document.createElement('div');
        backdrop.classList.add('eap_backdrop');
        container.appendChild(backdrop);
    }
    container.appendChild(pop);
    return pop;
};

const last = (arr=[])=>arr[arr.length-1];

function Popup_inner(props){
    const {title, children, on_close, on_ok, ok_lock, hide_footer,
        hide_header, coords, width, height, max_height, no_padding,
        submit_label, className, elevated} = props;
    const wrapper_ref = useRef();
    const panel_ref = useRef();
    const [styles, set_styles] = useState();
    useEffect(()=>{
        const res = {};
        if (coords)
        {
            if (coords.y >= 0)
                res.top = coords.y;
            else
                res.bottom = -coords.y;
            if (coords.x >= 0)
                res.left = coords.x;
            else
                res.right = -coords.x;
        }
        if (max_height)
            res.maxHeight = max_height;
        const {clientWidth, clientHeight} = wrapper_ref.current;
        let {offsetWidth, offsetHeight} = panel_ref.current;
        if (width)
        {
            res.width = width;
            offsetWidth = width;
        }
        if (height)
        {
            res.height = height;
            offsetHeight = height;
        }
        if (res.left+offsetWidth>clientWidth)
        {
            delete res.left;
            res.right = 0;
        }
        if (res.top+offsetHeight>clientHeight)
        {
            delete res.top;
            res.bottom = 0;
        }
        set_styles(res);
    }, [children]);
    const handle_wrapper_click = useCallback(e=>{
        if (e.target==wrapper_ref.current)
            on_close();
    }, [on_close]);
    let Tag = typeof title == 'string' ? Text.xxl : 'div';
    return <div className={cn('eap_wrapper', className)}
      onClick={handle_wrapper_click} ref={wrapper_ref}>
      <Panel className={cn('eap_container', coords&&'eap_container_abs',
        no_padding&&'eap_container_no_padding',
        hide_footer&&'eap_container_no_footer')} no_padding elevated={elevated}
        style={styles} ref={panel_ref}>
        {!hide_header&&<div className="eap_header">
          <Tag className="eap_title" bold>{title}</Tag>
          <button className="eap_close" onClick={on_close}>
            <Icon ico="close" color="dark" title="Close"/>
          </button>
        </div>}
        <div className="eap_body">
          {children}
        </div>
        {!hide_footer&&<div className="eap_footer">
          <Button className="eap_action" size="m"
            on_click={on_close}>Cancel</Button>
          <Button className="eap_action" size="m" filled color_scheme="primary"
            on_click={on_ok} disabled={ok_lock}>
            {submit_label || 'Apply'}
          </Button>
        </div>}
     </Panel>
    </div>;
}

function Popup(props){
    const {title='', show, children, on_close, on_ok, ok_lock, hide_actions,
        hide_header, on_close_finished, coords, width, max_height, no_padding,
        height, no_backdrop, submit_label, className, has_shadow} = props;
    const [id] = useState(()=>serial++);
    const [open, set_open] = useState(null);
    const [wrapper, set_wrapper] = useState(null);
    const [closing, set_closing] = useState(false);
    const handle_close = useCallback(()=>{
        // ensure_close
        if (on_close&&!closing)
            on_close();
    }, [closing, on_close]);
    const handle_esc = useCallback(e=>{
        if (e.key=='Escape'&&last(popups)==id)
            handle_close();
    }, [handle_close]);
    useEffect(()=>{
        const invoke = closing;
        return ()=>{
            if (invoke&&on_close_finished)
                on_close_finished();
        };
    }, [closing, on_close_finished]);
    useEffect(()=>{
        let elem;
        if (open)
        {
            popups.push(id);
            elem = activate_popup({no_backdrop});
            set_wrapper(elem);
            document.addEventListener('keyup', handle_esc, {passive: true});
        }
        return ()=>{
            if (open)
            {
                popups.pop();
                document.removeEventListener('keyup', handle_esc);
                elem.classList.add('eap_popup_out');
                set_closing(true);
            }
            let remove_backdrop = popups.length==0&&open&&!no_backdrop;
            if (remove_backdrop)
                backdrop.classList.add('eap_backdrop_out');
            setTimeout(()=>{
                if (open)
                {
                    elem.remove();
                    set_closing(false);
                    set_wrapper(null);
                    if (remove_backdrop)
                        backdrop.remove();
                }
            }, CLOSE_ANIMATION_DURATION+10);
        };
    }, [open]);
    useEffect(()=>{
        set_open(!!show);
    }, [show]);
    if (!wrapper)
        return null;
    const content = <Popup_inner title={title} on_close={handle_close}
      on_ok={on_ok} ok_lock={ok_lock} hide_footer={hide_actions}
      hide_header={hide_header} coords={coords} width={width} height={height}
      max_height={max_height} no_padding={no_padding} elevated={has_shadow}
      submit_label={submit_label} className={className}>
      {children}
    </Popup_inner>;
    return createPortal(content, wrapper);
}

E.Popup = Popup;
E.CONTAINER_CLASS = CONTAINER_CLASS;
return E;
});
