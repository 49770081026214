// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', 'react-router-dom', '/www/earnapp_cp/pub/comp/panel.js',
    'classnames', '/www/earnapp_cp/pub/utils/google_oauth.js',
    '/www/earnapp_cp/pub/comp/text.js',
    '/www/earnapp_cp/pub/comp/page',
    '/www/earnapp_cp/pub/comp/apple_login.js',
    '/www/earnapp_cp/pub/utils/constants.js',
    '/www/earnapp_cp/pub/utils/path.js',
    '/www/earnapp_cp/pub/comp/oauth_btn.js',
    '/www/earnapp_cp/pub/utils/path.js',
    // Import only
    'css!/www/earnapp_cp/pub/css/signup'],
    (React, react_router, {Panel}, cn, {get_url}, {Text}, {Page}, apple_login,
    {IOS_WEBVIEW}, {g_path, get_path}, {Oauth_btn})=>
{
const E = {};
const {Link} = react_router;

function Earn_block(){
    return <div className="eas_earn">
      <Text.xxl h1 bold className="eas_earn_title">
        Earn passive income while your devices rest</Text.xxl>
      <Text.xl className="eas_earn_description">
        Make big money, safely, by sharing the unused resources on your resting
        devices
      </Text.xl>
    </div>;
}

function Howto_block(){
    return <div className="eas_howto">
      <Text.xxl h2 bold className="eas_how_title">How does it work?</Text.xxl>
      <ul className="eas_steps">
        <li className="eas_step eas_step_download">
          <Text.m>Download</Text.m></li>
        <li className="eas_step eas_step_install">
          <Text.m>Install</Text.m></li>
        <li className="eas_step eas_step_earn">
          <Text.m>Earn</Text.m></li>
      </ul>
    </div>;
}

class Signup_block extends React.Component {
    constructor(props){
        super(props);
        apple_login.init.call(this, {force_beta: IOS_WEBVIEW});
    }
    componentDidMount(){ apple_login.prepare.call(this); }
    render(){
        const {className} = this.props;
        const apple_login_btn = apple_login.render.call(this,
            ['Sign up with Apple']);
        return <Panel className={cn('eas_signup', className)} no_background
          no_border>
          <Text.m className="eas_title" h2 bold>Sign Up</Text.m>
          <div className="eas_separator"/>
          <ul className="eas_actions">
            {!IOS_WEBVIEW && <li className="eas_action">
              <Oauth_btn url={get_url(true)}
                logo="google_logo">Sign up with Google</Oauth_btn>
            </li>}
            {apple_login_btn && <li className="eas_action">{apple_login_btn}
            </li>}
          </ul>
          <div className="eas_switch">
            <Text.s className="eas_qestion">Already have an account?</Text.s>
            <Link className="eas_link" to={get_path(g_path('/signin'))}>
              Sign in</Link>
          </div>
        </Panel>;
    }
}

function Signup(){
    return <Page className="ea_signup" sidebarless headerless has_logo>
      <div className="eas_row">
        <Signup_block/>
        <Earn_block/>
      </div>
      <Howto_block/>
      <div className="eas_signup_double_wrapper">
        <Signup_block className="eas_signup_double"/></div>
    </Page>;
}

E.Signup = Signup;
return E;
});
