// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', 'classnames',
    '/www/earnapp_cp/pub/comp/text.js',
    '/www/earnapp_cp/pub/comp/button',
    '/www/earnapp_cp/pub/comp/icon',
    // Import only
    'css!/www/earnapp_cp/pub/css/oauth_btn'],
    (React, cn, {Text}, {Button}, {Icon})=>
{
const E = {};

function Oauth_btn({className, children, logo='', url}){
    return <Button href={url} className={cn('ea_oauth_btn', className)}
      size="m" in_new_tab={false} color_scheme="primary" filled>
      <Icon className="eaob_logo" ico={logo} as="img"/>
      <Text.s span bold white>{children}</Text.s>
    </Button>;
}

E.Oauth_btn = Oauth_btn;
return E;
});
