// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import Tooltip_comp from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import '../css/tooltip.less';

const TRIGGERS = ['click', 'hover'];
const CLASSNAMES = {inner: 'ea_tooltip_inner', root: 'ea_tooltip'};
const DELAY_SEC = 0.2;

export function Tooltip({
    tip,
    children,
    trigger=TRIGGERS,
    placement='bottom',
    show_delay_s=DELAY_SEC,
    hide_delay_s=DELAY_SEC,
})
{
    if (!tip)
        return children;
    return <Tooltip_comp placement={placement}
      trigger={trigger} overlay={tip} animation="zoom" destroyOnClose
      showArrow={false} mouseEnterDelay={show_delay_s}
      mouseLeaveDelay={hide_delay_s} classNames={CLASSNAMES}>
      {children}
    </Tooltip_comp>;
}
