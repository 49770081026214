// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import date from '/util/date.js';
import {g_path, INITIAL_QP} from '../utils/path.js';
import {use_xsrf_rotation} from '../utils/misc.js';
import {REF_Q_PARAM} from '../utils/constants.js';
import {Signin} from '../pages/signin.js';
import {Signup} from '../pages/signup.js';
import {Auth_success} from '../pages/auth_success.js';
import {Loading} from '../comp/loading.js';
import api from '../api.js';
import '../css/index.less';

function App(){
    let ref;
    if (ref = INITIAL_QP[REF_Q_PARAM])
    {
        document.cookie = `referral_code=${ref}; expires=${
            date.add(date(), {days: 7})}; path=/; domain=earnapp.com;`;
    }
    const ready = use_xsrf_rotation(api.rotate_xsrf);
    if (!ready)
        return <Loading/>;
    return <BrowserRouter>
      <Switch>
        <Route path={g_path('/signin')} exact component={Signin}/>
        <Route path={g_path('/signup')} exact component={Signup}/>
        <Route path={g_path('/auth/google/callback')} exact
          component={Auth_success}/>
        <Redirect to={g_path('')}/>
      </Switch>
    </BrowserRouter>;
}

ReactDOM.render(<App/>, document.getElementById('root'));
