// LICENSE_CODE ZON
'use strict'; /*jslint browser:true, es9:true*/
define(['cookie', '/util/etask.js', '/util/ajax.js',
    '/www/earnapp_cp/pub/utils/path.js',
    '/www/earnapp_cp/pub/utils/constants.js',
    '/www/earnapp_cp/pub/utils/local_storage.js'],
    (cookie, etask, ajax, path, constants, local_storage)=>
{
const {g_path, get_location, parse_qs} = path;
const {APP_ID, VERSION, STORAGE_KEYS, XSRF_COOKIE, XSRF_HEADER} = constants;
const E = {};
const ENDPOINTS = {
    LOGIN_URL: g_path('/api/login_url'),
    USER_DATA: g_path('/api/user_data'),
    DELETE_USER: g_path('/api/user/request_delete'),
    UNDELETE_USER: g_path('/api/user/request_delete_cancel'),
    USER: g_path('/api/user'),
    RESTORE_USER: g_path('/api/restore_user'),
    DEVICES: g_path('/api/devices'),
    BONUSES: g_path('/api/bonuses'),
    MONEY: g_path('/api/money'),
    DEVICE: g_path('/api/device/'),
    LINK_DEVICE: g_path('/api/link_device'),
    EDIT_DEVICE: g_path('/api/edit_device/'),
    SHOW_DEVICE: g_path('/api/show_device'),
    HIDE_DEVICE: g_path('/api/hide_device'),
    ONBOARDING: g_path('/api/onboarding'),
    TOKEN: g_path('/api/token'),
    LOGOUT: g_path('/api/logout'),
    REDEEM: g_path('/api/redeem'),
    REFEREES: g_path('/api/referees'),
    REFEREES_BVPN: g_path('/api/referees_bvpn'),
    COUNTERS: g_path('/api/counters'),
    NOTIFS: g_path('/api/notifications'),
    REDEEM_DETAILS: g_path('/api/redeem_details'),
    TRANSACTIONS: g_path('/api/transactions'),
    SPEEDTEST: g_path('/api/speedtest'),
    SPEEDTEST_CSV: g_path('/api/speedtest_csv'),
    DOWNLOADS: g_path('/api/downloads'),
    P_METHODS: g_path('/api/payment_methods'),
    LEADERBOARD: g_path('/api/leaderboard'),
    USAGE: g_path('/api/usage'),
    DEVICE_STATUSES: g_path('/api/device_statuses'),
    SPEEDTEST_SHARE: g_path('/api/speedtest_share'),
    // root
    HAS_USER: g_path('/api/has_user'),
    USER_STATUS: g_path('/api/user/status'),
    RAW_MONGO_DEVICE: g_path('/api/raw_mongo_device/'),
    // misc
    XSRF: g_path('/api/sec/rotate_xsrf'),
    ZEN_SSO: 'https://earnapp.com/dashboard/api/zendesk_sso',
    OFW: g_path('/api/offerwall'),
};
const POST = 'post';
const PUT = 'put';
const GET = 'get';
const DELETE = 'delete';

const ALLOWED_QP = [];

const get_query_params = (params={})=>{
    const rec = {appid: APP_ID, version: VERSION, ...params};
    const user = local_storage.read(STORAGE_KEYS.USER);
    const qs_params = parse_qs();
    for (const p of ALLOWED_QP)
    {
        if (qs_params[p])
            rec[p] = qs_params[p];
    }
    if (user)
        rec.u = user;
    return rec;
};

ajax.events.on('unauthorized', ()=>{
    document.location = g_path(`/signin?redirect=${get_location()}`);
});

ajax.events.on('maintenance', ()=>{
    document.location = g_path('/maintenance');
});

const send = method=>(url, opts={})=>{
    const {qp, data} = opts;
    const query_params = get_query_params(qp);
    let xsrf_token;
    if (data && XSRF_HEADER in data)
    {
        xsrf_token = opts.data[XSRF_HEADER];
        delete opts.data[XSRF_HEADER];
    }
    else
        xsrf_token = cookie.get(XSRF_COOKIE);
    const headers = {
        [XSRF_HEADER]: xsrf_token,
    };
    return ajax[method]({url, qs: query_params, data, headers});
};

const fetch_user = ()=>etask(function*(){
    const res = yield send(GET)(ENDPOINTS.USER_DATA);
    if (res.error)
        return res;
    return {
        email: res.email,
        name: (res.name||'').trim(),
        first_name: (res.first_name||'').trim(),
        last_name: (res.last_name||'').trim(),
        picture: res.picture||'',
        locale: res.locale||'en',
        joined_ts: res.joined_ts,
        referral_code: res.referral_code||'',
        onboarding: res.onboarding||'',
        delete_ts: res.delete_ts||'',
        is_root: res.is_root||false,
        suspend_reason: res.suspend_reason||'',
        req_delete: res.req_delete||'',
        bvpn_ref_enabled: res.bvpn_ref_enabled||false,
    };
});

const delete_account = ()=>send(PUT)(ENDPOINTS.DELETE_USER);
const undelete_account = ()=>send(PUT)(ENDPOINTS.UNDELETE_USER);

const fetch_transactions = ()=>send(GET)(ENDPOINTS.TRANSACTIONS);

const fetch_speedtest = ()=>send(GET)(ENDPOINTS.SPEEDTEST);

const fetch_devices = ()=>send(GET)(ENDPOINTS.DEVICES);

const fetch_money = ()=>send(GET)(ENDPOINTS.MONEY);

const fetch_leaderboard = ()=>send(GET)(ENDPOINTS.LEADERBOARD);

const link_device = ({code, platform})=>send(POST)(ENDPOINTS.LINK_DEVICE, {
    data: {uuid: code, platform},
});

const edit_device = (uuid, data)=>send(PUT)(ENDPOINTS.EDIT_DEVICE+uuid,
    {data});

const raw_mongo_device = uuid=>send(GET)(ENDPOINTS.RAW_MONGO_DEVICE+uuid);

const toggle_device = (uuid, show)=>{
    const endpoint = show ? ENDPOINTS.SHOW_DEVICE : ENDPOINTS.HIDE_DEVICE;
    return send(PUT)(endpoint, {data: {uuid}});
};

const delete_device = uuid=>send(DELETE)(ENDPOINTS.DEVICE+uuid);

const exchange_token = data=>send(POST)(ENDPOINTS.TOKEN, {data});

const logout = ()=>send(POST)(ENDPOINTS.LOGOUT);

const redeem = (to, payment_method)=>send(POST)(ENDPOINTS.REDEEM, {
    data: {to, payment_method},
});

const set_redeem_details = (to, payment_method)=>
    send(POST)(ENDPOINTS.REDEEM_DETAILS, {data: {to, payment_method}});

const disable_redeem = ()=>send(DELETE)(ENDPOINTS.REDEEM_DETAILS);

const fetch_downloads = ()=>send(GET)(ENDPOINTS.DOWNLOADS);

const get_usage = (step='daily')=>send(GET)(ENDPOINTS.USAGE, {qp: {step}});

const fetch_payment_methods = ()=>send(GET)(ENDPOINTS.P_METHODS);

const fetch_referees = (page=0)=>send(GET)(ENDPOINTS.REFEREES, {qp: {page}});

const fetch_referees_bvpn = (page=0)=>send(GET)(ENDPOINTS.REFEREES_BVPN,
    {qp: {page}});

const fetch_counters = ()=>send(GET)(ENDPOINTS.COUNTERS);

const fetch_notifs = ()=>etask(function*(){
    const r = yield send(GET)(ENDPOINTS.NOTIFS);
    if (r.error)
        return r;
    return {list: r};
});

const fetch_bonuses = ()=>etask(function*(){
    const r = yield send(GET)(ENDPOINTS.BONUSES);
    if (r.error)
        return r;
    return {list: r};
});

const mark_onboarding = ()=>send(PUT)(ENDPOINTS.ONBOARDING);

const has_user = search=>send(GET)(ENDPOINTS.HAS_USER, {qp: {search}});

const update_user_status = (email, action, suspend_reason, with_email)=>
    send(PUT)(ENDPOINTS.USER_STATUS, {qp: {email, action, suspend_reason,
    with_email}});

const rotate_xsrf = ()=>send(GET)(ENDPOINTS.XSRF);

const get_device_statuses = devices=>send(POST)(ENDPOINTS.DEVICE_STATUSES,
    {data: {devices}});

const get_speedtest_csv_path = (uuid, month)=>
    `${ENDPOINTS.SPEEDTEST_CSV}?appid=${APP_ID}&uuid=${uuid}&month=${month}`;

const speedtest_share = data=>send(POST)(ENDPOINTS.SPEEDTEST_SHARE, {data});

const get_speedtest_share = share_id=>send(GET)(ENDPOINTS.SPEEDTEST_SHARE, {
    qp: {share_id}});

const get_login_url = (provider, beta)=>{
    const qs = beta ? '?beta=1' : '';
    return send(GET)(ENDPOINTS.LOGIN_URL+`/${provider}${qs}`);
};

const get_zendesk_sso = return_to=>send(GET)(ENDPOINTS.ZEN_SSO
    +'?return_to='+return_to);

const fetch_offers = ()=>etask(function*(){
    const agent = encodeURIComponent(navigator.userAgent);
    let hints = '';
    if (navigator.userAgentData)
    {
        try {
            hints = yield navigator.userAgentData.getHighEntropyValues(
              ['architecture', 'bitness', 'brands', 'mobile', 'model',
              'platform', 'platformVersion', 'uaFullVersion',
              'fullVersionList', 'wow64']);
            hints = encodeURIComponent(JSON.stringify(hints));
        } catch(e){
          console.log(e);
        }
    }
    const qp = {
        hints,
        agent,
        category: 'all',
        offer_type: 'web',
    };
    const res = yield send(GET)(ENDPOINTS.OFW, {qp});
    if (res.error)
        return res;
    return res;
});

E.fetch_user = fetch_user;
E.delete_account = delete_account;
E.undelete_account = undelete_account;
E.fetch_devices = fetch_devices;
E.fetch_money = fetch_money;
E.fetch_downloads = fetch_downloads;
E.fetch_payment_methods = fetch_payment_methods;
E.fetch_transactions = fetch_transactions;
E.fetch_speedtest = fetch_speedtest;
E.fetch_referees = fetch_referees;
E.fetch_referees_bvpn = fetch_referees_bvpn;
E.fetch_counters = fetch_counters;
E.fetch_notifs = fetch_notifs;
E.fetch_bonuses = fetch_bonuses;
E.link_device = link_device;
E.edit_device = edit_device;
E.raw_mongo_device = raw_mongo_device;
E.toggle_device = toggle_device;
E.delete_device = delete_device;
E.get_device_statuses = get_device_statuses;
E.exchange_token = exchange_token;
E.logout = logout;
E.redeem = redeem;
E.set_redeem_details = set_redeem_details;
E.disable_redeem = disable_redeem;
E.mark_onboarding = mark_onboarding;
E.fetch_leaderboard = fetch_leaderboard;
E.has_user = has_user;
E.update_user_status = update_user_status;
E.get_usage = get_usage;
E.rotate_xsrf = rotate_xsrf;
E.get_speedtest_csv_path = get_speedtest_csv_path;
E.speedtest_share = speedtest_share;
E.get_speedtest_share = get_speedtest_share;
E.get_login_url = get_login_url;
E.get_zendesk_sso = get_zendesk_sso;
E.fetch_offers = fetch_offers;

return E;
});
