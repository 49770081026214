// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', 'classnames', '/util/etask.js',
    '/www/earnapp_cp/pub/comp/button',
    '/www/earnapp_cp/pub/comp/text.js',
    '/www/earnapp_cp/pub/comp/popup.js',
    '/www/earnapp_cp/pub/comp/alert.js',
    '/www/earnapp_cp/pub/store.js',
    '/www/earnapp_cp/pub/api.js',
    // Import only
    'css!/www/earnapp_cp/pub/css/delete_account'],
    (React, cn, etask, {Button}, {Text}, {Popup}, {alert}, store, api)=>
{
const E = {};
const {useState, useCallback} = React;

function Delete_account({className, user, on_close}){
    let cancel = false;
    const [show_popup, set_show_popup] = useState(false);
    const [show_note_popup, set_show_note_popup] = useState(false);
    const toggle_popup = useCallback(()=>set_show_popup(s=>!s), []);
    const close_note_popup = useCallback(()=>{
        set_show_note_popup(false);
        on_close?.();
    }, []);
    const delete_account = useCallback(()=>etask(function*(){
        this.on('uncaught', console.error);
        let res;
        try {
            if (cancel)
                res = yield api.undelete_account(user.email);
            else
                res = yield api.delete_account(user.email);
        } catch(e){ res = {error: e.message}; }
        if (res.error)
        {
            const title = cancel ? 'Error while canceling the request'
                : 'Error while deleting account';
            alert({title, text: res.error, negative: true});
        }
        else
        {
            const text = cancel ? 'Account deletion request was canceled'
                : 'Account deletion request has successfully sent';
            alert({text, positive: true});
            store.use_user.invalidate();
            toggle_popup();
            set_show_note_popup(true);
        }
    }), [user]);
    // user should not be able to request deletion if suspended
    if (user.suspend_reason && !user.is_root)
        return null;
    cancel = user.delete_ts && user.req_delete;
    let title = 'Delete account';
    // not that checks inverted to display proper note after action is complete
    if (cancel)
    {
        title = 'Cancel delete request';
        return <React.Fragment>
          <Button className={cn('ea_delete_account_delete', className)}
              color_scheme="primary" filled on_click={toggle_popup}>{title}
          </Button>
          <Popup title={title} show={show_popup}
            on_close={toggle_popup} hide_actions>
            <div className="ea_delete_account_popup">
              <Text.s className="ea_delete_account_subtitle">
                Are you sure you would like to cancel
                your deletion request?
              </Text.s>
              <Text.s>You will start earning immediately. You can delete your
                account anytime if you change your mind.</Text.s>
              <div className="ea_delete_account_actions">
                <Button color_scheme="primary" filled
                  className="ea_delete_account_close"
                  on_click={delete_account}>Yes, cancel my request</Button>
                <Button color_scheme="error"
                  className="ea_delete_account_cancel"
                  on_click={toggle_popup}>No, I still want to delete my account
                </Button>
              </div>
            </div>
          </Popup>
          <Popup title="Delete account request sent"
            show={show_note_popup} hide_actions on_close={close_note_popup}>
            <div className="ea_delete_account_note_popup">
              <Text.l className="ea_delete_account_note">NOTE</Text.l>
              <Text.m>Your request was received. You are no longer earning.
                Please uninstall the app. Your account will be permanently
                deleted in 3 business days and you will receive a
                confirmation email.
              </Text.m>
            </div>
          </Popup>
        </React.Fragment>;
    }
    return <React.Fragment>
      <Button className={cn('ea_delete_account_delete', className)}
        filled color_scheme="error" view="link" on_click={toggle_popup}>
        {title}
      </Button>
      <Popup title={title} show={show_popup}
        on_close={toggle_popup} hide_actions>
        <div className="ea_delete_account_popup">
          <Text.s>If you decide to delete your account, you will stop earning
            money and lose you unredeemed balance.</Text.s>
          <Text.s className="ea_delete_account_subtitle">Are you sure you would
            like to delete your account?</Text.s>
          <div className="ea_delete_account_actions">
            <Button color_scheme="error" filled
              className="ea_delete_account_close"
              on_click={delete_account}>Yes, delete my account</Button>
            <Button className="ea_delete_account_cancel"
              on_click={toggle_popup}>No, I want to keep earning</Button>
          </div>
        </div>
      </Popup>
      <Popup title="Delete account request canceled"
        show={show_note_popup} hide_actions on_close={close_note_popup}>
        <div className="ea_delete_account_note_popup">
          <Text.l className="ea_delete_account_note">NOTE</Text.l>
          <Text.m>Your request was canceled. Your apps are earning again.
            You can delete your account anytime if you change your mind.
            Thanks for using EarnApp!
          </Text.m>
        </div>
      </Popup>
    </React.Fragment>;
}

E.Delete_account = Delete_account;
return E;
});
