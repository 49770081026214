// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['/www/earnapp_cp/pub/utils/constants.js'], ({LS_PREFIX})=>{
const E = {};

const get_key = key=>`${LS_PREFIX}_${key}`;

const pick_storage=opts=>opts.session ?
    window.sessionStorage : window.localStorage;

E.write = (_key, _value, opts={})=>{
    const key = get_key(_key);
    try {
        const value = JSON.stringify(_value);
        const storage = pick_storage(opts);
        storage.setItem(key, value);
        return true;
    } catch(e){
        console.error(e);
    }
    return false;
};

E.read = _key=>{
    const key = get_key(_key);
    try {
        let raw = sessionStorage.getItem(key);
        if (!raw)
            raw = localStorage.getItem(key);
        return JSON.parse(raw);
    } catch(e){
        console.error(e);
    }
};

E.cleanup = _key=>{
    const key = get_key(_key);
    try {
        sessionStorage.removeItem(key);
        localStorage.removeItem(key);
        return true;
    } catch(e){
        console.error(e);
    }
    return false;
};

return E;
});
