// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
define(['react', 'classnames',
    // Import only
    'css!/www/earnapp_cp/pub/css/panel'],
    (React, cn)=>
{
const E = {};

const Panel = React.forwardRef(function Panel(props, ref){
    const {as='div', children, className, no_border, no_padding,
        elevated, no_background, style = {}} = props;
    const Wrapper = as;
    const classnames = cn('ea_panel', no_border&&'eap_no_border',
        elevated&&'eap_elevated', no_padding&&'eap_no_padding',
        no_background&&'eap_no_background', className);
    return <Wrapper ref={ref} className={classnames} style={style}>
      {children}</Wrapper>;
});

E.Panel = Panel;
return E;
});
