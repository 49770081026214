// LICENSE_CODE ZON
'use strict'; /*jslint node:true, browser:true, es6:true*/
(function(){
let define;
let is_node = typeof module=='object' && module.exports;
if (!is_node)
    define = self.define;
else
    define = require('../../../util/require_node.js').define(module, '../');
define(['/util/date.js'],
    function(date)
{
const E = {};

E.STATUS_CREATED = 'created';
E.STATUS_APPROVED = 'approved';
E.STATUS_REJECTED = 'rejected';
E.STATUS_PENDING_PROCEDURE = 'pending_procedure';
E.STATUS_PAID = 'paid';
E.STATUS_NOT_PAID = 'not_paid';
E.STATUS_CANCELED = 'canceled';
E.STATUS_PENDING_PAID = 'pending_paid';

E.USER_SUSPENDED = 'user_suspended';
E.USER_SUSPENDED_EMAIL = 'user_suspended_email';
E.USER_LIVE = 'user_live';

E.REDEEM_STATUSES_NON_FINAL = [
    E.STATUS_CREATED,
    E.STATUS_APPROVED,
    E.STATUS_REJECTED,
    E.STATUS_PENDING_PROCEDURE,
    E.STATUS_PENDING_PAID,
];

E.REDEEM_STATUSES_FINAL = [
    E.STATUS_PAID,
];

E.MAJOR_REDEEM_STATUSES = [].concat(
    E.REDEEM_STATUSES_NON_FINAL,
    E.REDEEM_STATUSES_FINAL
);

E.round_money = (amount, dec=2)=>{
    if (!amount||isNaN(amount))
        return 0;
    const mul = Math.pow(10, dec);
    return parseFloat(Math.floor(amount*mul)/mul.toFixed(dec));
};

E.parse_pp_date = (d, t)=>
    date.parse(`${d} ${t||'00:00:00'}`, {fmt: '%d/%m/%Y %H:%M:%S'});

return E; }); }());
