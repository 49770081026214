// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {Icon} from '../icon';
import {Text} from '../text.js';
import * as cns from './user.module.less';

const DEFAULT_ADAPTIVE = {
    hide_name: true,
};

export function User({
    user,
    on_click,
    adaptive_opts=DEFAULT_ADAPTIVE,
    big,
    no_bg,
})
{
    const Comp = on_click ? 'button' : 'div';
    const classes = cn(cns.root, {
        [cns.root__clickable]: !!on_click,
        [cns.root__narrow_hide_name]: !!adaptive_opts?.hide_name,
        [cns.root__big]: !!big,
        [cns.root__no_bg]: !!no_bg,
    });
    const props = {};
    if (on_click)
        props.onClick = on_click;
    const Name_comp = big ? Text.xxl : Text.s;
    return <Comp className={classes} {...props}>
      <Icon className={cns.avatar} as="img"
        title={user.name} src={user.picture} ico="default_avatar"/>
      <div className={cns.name_wrap}>
        {big&&<Text.l tertiary className={cns.name}>User name</Text.l>}
        <Name_comp className={cns.name}>{user.name}</Name_comp>
      </div>
    </Comp>;
}
